<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ trans("menu.settings") }}
      </h3>
    </div>
    <div class="panel-body col-md-12">
      <div class="row col-md-12 my-3 tw-gap-y-8">
        <div class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.regionskaneDicom.active"
            label="Region Skåne - DICOM"
          />
        </div>

        <div class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.educations.active"
            label="Educations"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.image_quality_assurance.active"
            :label="trans('admin.imageQualityAssurance')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.image_polarisation_check.active"
            :label="trans('admin.imagePolarisationCheck')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.ai_prioritization.active"
            :label="trans('admin.aiPrioritization')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.ai_diagnosis.active"
            :label="trans('admin.aiDiagnosis')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.mobile_two_factor_authentication.active"
            :label="trans('admin.mobileTwoFactorAuth')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="center.is_lab_pharmacy"
            label="Lab/pharmacy (hides fields from Mole form)"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="center.patient_telephone_number"
            label="Require patient telephone number"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.hide_patient_fields.active"
            :label="trans('admin.hidePatientFields')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.wound_measurement.active"
            :label="trans('admin.woundMeasurementSetting')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.external_resources_setting.active"
            :label="trans('admin.externalResourceSetting')"
          />
        </div>

        <div v-if="center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.ucr_promotion.active"
            :label="trans('admin.showUCRPromotion')"
          />
        </div>

        <div v-if="hasMcdSetting && center" class="col-md-2 col-sm-3">
          <DerToggleWithLabel
            v-model="settings.send_incoming_cases_to_mcd_service.active"
            :label="trans('admin.sendIncomingCasesToMCD')"
          />
        </div>
      </div>
    </div>

    <div v-if="center" class="card-deck">
      <div class="card">
        <div class="card-header">
          {{ trans("examination.ucr_feature_name") }}
        </div>
        <div class="card-body">
          <div class="card-text col-md-12">
            <DerToggleWithLabel
              v-model="settings.ucr.active"
              disabled
              :label="`UCR ${
                !settings.ucr.active
                  ? `(${trans('examination.ucr_sync_info')})`
                  : ''
              }`"
            />
          </div>

          <div v-if="settings.ucr.active">
            <strong
              >{{ trans("examination.ucr_center_synced_id") }}:
              {{ center.ucr_id }}</strong
            >
          </div>
        </div>
      </div>

      <Webdoc v-if="platformHasWebdoc" :settings="settings" />

      <div class="card">
        <div class="card-header">Default reserve number prefix:</div>
        <div class="card-body">
          <label class="card-text col-md-12">
            Enable:
            <DerToggle
              v-model="settings.default_reserve_number_prefix.active"
            />
          </label>
          <div class="card-text">
            <label class="col-md-12">
              Default reserve number prefix
              <input
                v-model="
                  settings.default_reserve_number_prefix.config.reserve_number
                "
                type="text"
                class="form-control"
                :disabled="!settings.default_reserve_number_prefix.active"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Webdoc from "./Webdoc.vue";
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    Webdoc,
    DerToggleWithLabel,
  },
  props: {
    hasMcdSetting: Boolean,
    organisation: String,
    platformHasWebdoc: Boolean,
    settings: {
      send_incoming_cases_to_mcd_service: {
        active: false,
      },
      ucr_promotion: {
        active: false,
      },
      ucr: {
        active: false,
        config: {
          center_id: null,
        },
      },
      regionskaneDicom: {
        active: false,
      },
      educations: {
        active: false,
      },
      defaultReserveNumberPrefix: {
        active: false,
        config: {
          reserve_number: "",
        },
      },
      webdoc: {},
    },
    center: {
      is_lab_pharmacy: Number,
      patient_telephone_number: Number,
    },
  },
};
</script>
