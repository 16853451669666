<template>
  <div class="card">
    <div class="card-header">Webdoc</div>
    <div class="card-body">
      <div class="card-text col-md-12 tw-mb-2">
        <DerToggleWithLabel v-model="settings.webdoc.active" label="Webdoc" />
      </div>
      <div class="card-text">
        <div
          v-for="key in Object.keys(settings.webdoc.config)"
          :key="key"
          class="col-md-12"
        >
          <label :for="key">{{
            key.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase())
          }}</label>
          <input
            v-model="settings.webdoc.config[key]"
            type="text"
            class="form-control"
            :disabled="!settings.webdoc.active"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    DerToggleWithLabel,
  },
  props: ["settings"],
};
</script>
